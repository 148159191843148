// Full Privacy Policy content
const privacyPolicyContent = `
<h2>Privacy Policy</h2>
<strong>Effective Date:</strong> September 23, 2024<br/><br/>

<strong>Introduction</strong><br/>
Your privacy is important to Bragr Inc. (“Bragr,” “we,” “us,” or “our”). This Privacy Policy describes how we collect, use, disclose, and protect your personal information when you use our website, mobile application, and related services (collectively, the “Services”). By using our Services, you agree to this Privacy Policy. If you do not agree, please do not use our Services.<br/><br/>

We may modify this Privacy Policy from time to time to reflect changes in our practices or applicable law. Any modifications will be effective from the date specified in the updated policy. We encourage you to review this Privacy Policy periodically to stay informed about our data practices.<br/><br/>

<strong>1. Information We Collect</strong><br/>
<strong>Information You Provide Directly</strong><br/>
We collect information that you voluntarily provide when you use our Services, including:
<ul>
  <li><strong>Contact Information:</strong> Such as your name, email address, postal address, and phone number.</li>
  <li><strong>Account Information:</strong> Including your username, password, and other information related to your account.</li>
  <li><strong>Payment Information:</strong> Financial information such as credit card details, which are processed by third-party payment processors and not stored on our servers.</li>
  <li><strong>Profile Information:</strong> Information about your preferences, interests, and any additional details you choose to provide in your profile.</li>
  <li><strong>Communications:</strong> Data you provide when you contact us for support, participate in surveys, or engage with us through other means.</li>
</ul><br/>

<strong>Information Collected Automatically</strong><br/>
We may automatically collect information about your use of our Services, including:
<ul>
  <li><strong>Usage Data:</strong> Details about your interactions with the Services, such as pages visited and features used.</li>
  <li><strong>Device Information:</strong> Information about the device you use, such as IP address, browser type, operating system, and mobile device identifiers.</li>
  <li><strong>Location Data:</strong> General geographic location inferred from your IP address or more precise location data with your consent.</li>
  <li><strong>Cookies and Similar Technologies:</strong> Data collected through cookies, web beacons, and similar technologies to analyze user behavior and improve our Services.</li>
</ul><br/>

<strong>Information from Third Parties</strong><br/>
We may receive information about you from third-party sources, such as:
<ul>
  <li><strong>Social Media Platforms:</strong> Information you share with us through your social media accounts when linked to your Bragr account.</li>
  <li><strong>Analytics Providers:</strong> Data to help us understand how users interact with our Services.</li>
  <li><strong>Publicly Available Sources:</strong> Information from public databases or other publicly accessible sources.</li>
</ul><br/>

<strong>2. How We Use Your Information</strong><br/>
We use your personal information for various purposes, including to:
<ul>
  <li><strong>Provide and Enhance Services:</strong> To operate and maintain our Services, personalize your experience, and deliver the products and services you request.</li>
  <li><strong>Communicate with You:</strong> To send you account-related information, respond to your inquiries, and provide updates about our Services.</li>
  <li><strong>Process Transactions:</strong> To facilitate payments, manage orders, and provide related customer service.</li>
  <li><strong>Analyze and Improve Services:</strong> To conduct data analysis and research aimed at improving our Services, understanding user behavior, and developing new features.</li>
  <li><strong>Ensure Security:</strong> To detect, prevent, and respond to fraud, abuse, and other security issues.</li>
  <li><strong>Comply with Legal Obligations:</strong> To meet legal requirements and protect our rights and the rights of our users.</li>
</ul><br/>

<strong>3. How We Share Your Information</strong><br/>
<ul>
  <li><strong>Affiliates and Subsidiaries:</strong> We may share your information with our affiliates and subsidiaries for operational and business purposes.</li>
  <li><strong>Service Providers:</strong> We work with third-party service providers to perform functions such as hosting, data analysis, payment processing, and marketing. These providers are required to maintain the confidentiality of your information and use it only for the purposes for which it was provided.</li>
  <li><strong>Business Partners:</strong> We may collaborate with business partners to offer co-branded products or services. If you engage with such offerings, both Bragr and the partner may receive your information.</li>
  <li><strong>Legal Purposes:</strong> We may disclose your information if required to do so by law or if we believe such action is necessary to comply with legal obligations, protect and defend our rights or property, prevent or investigate possible wrongdoing, or protect the safety of our users or the public.</li>
  <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new entity as part of the business transaction.</li>
</ul><br/>

<strong>4. Cookies and Tracking Technologies</strong><br/>
We use cookies and similar technologies to enhance your experience on our Services. These technologies help us to:
<ul>
  <li><strong>Authenticate Users:</strong> Keep you logged in and recognize you when you return to our Services.</li>
  <li><strong>Analyze Performance:</strong> Understand how users interact with our Services and identify areas for improvement.</li>
  <li><strong>Personalize Content:</strong> Tailor content and advertisements to your interests and preferences.</li>
  <li><strong>Enable Features:</strong> Provide and improve functionalities within our Services.</li>
</ul><br/>

<strong>5. How We Protect Your Information</strong><br/>
We use a combination of technical, administrative, and physical safeguards to protect your personal information from unauthorized access, use, or disclosure. These measures include:
<ul>
  <li><strong>Encryption:</strong> Secure encryption of sensitive data during transmission and storage.</li>
  <li><strong>Access Controls:</strong> Restricting access to your personal information to only those employees, contractors, and agents who need it to operate our Services.</li>
  <li><strong>Security Monitoring:</strong> Regularly monitoring our systems for potential vulnerabilities and threats.</li>
</ul><br/>

<strong>6. Your Rights and Choices</strong><br/>
<ul>
  <li><strong>Access and Correction:</strong> You can access and update your personal information by logging into your account or contacting us at info@bragr.com.</li>
  <li><strong>Opt-Out of Marketing Communications:</strong> You can opt out of receiving marketing communications from us by following the unsubscribe instructions in our emails or by contacting us directly.</li>
  <li><strong>Cookies and Tracking Technologies:</strong> You can set your browser to refuse cookies or alert you when cookies are being sent. If you disable cookies, some parts of our Services may not function properly.</li>
  <li><strong>Deletion and Restriction:</strong> You may request that we delete or restrict the processing of your personal information under certain circumstances. To exercise these rights, please contact us at info@bragr.com.</li>
</ul><br/>

<strong>7. International Data Transfers</strong><br/>
Bragr is based in the United States, and your information may be transferred, stored, and processed in the United States or other countries outside of your country of residence. By using our Services, you consent to the transfer and processing of your information in the United States.<br/><br/>

<strong>8. Children’s Privacy</strong><br/>
Our Services are not intended for children under 21 years of age. We do not knowingly collect personal information from children under 21. If we become aware that we have collected information from a child under 21, we will delete it promptly. If you believe we have collected such information, please contact us at info@bragr.com.<br/><br/>

<strong>9. Retention of Your Information</strong><br/>
We retain your personal information as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, resolve disputes, and enforce our agreements. If you request deletion of your information, we will remove it as soon as reasonably practicable and as required by applicable law.<br/><br/>

<strong>10. Links to Other Sites</strong><br/>
Our Services may contain links to third-party websites. These websites have their own privacy policies, and we are not responsible for their content or privacy practices. We encourage you to review the privacy policies of any third-party sites you visit.<br/><br/>

<strong>11. Changes to This Privacy Policy</strong><br/>
We may update this Privacy Policy from time to time to reflect changes in our practices or applicable law. Any changes will be effective from the date specified in the updated policy. We will notify you of any material changes through email, notice on our Services, or other appropriate means. We encourage you to review this Privacy Policy periodically to stay informed of any updates.<br/><br/>

<strong>12. Contact Information</strong><br/>
If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
<br/>Email: info@bragr.com
<br/>Address: Bragr Inc., Colorado, USA
`;

export default privacyPolicyContent;


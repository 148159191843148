// AppContent.js

import React, { useState, useEffect, useRef } from "react";
import Window from "./sections/Window/Window";
import Header from "./sections/Header/Header";
import Loading from "./components/Loading/Loading";
//import AgeConfirmation from './components/AgeConfirmation/AgeConfirmation';
import LimitReached from './components/LimitReached/LimitReached';
import "./App.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useToken } from "./context/TokenContext";
import { identifyUser, trackEvent } from './utils/analytics';

const baseUrl = process.env.REACT_APP_BASE_URL;

const AppContent = () => {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();
  const { fetchAccessToken } = useToken();
  const [chats, setChats] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(0);
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  //const [showAgeConfirmation, setShowAgeConfirmation] = useState(true);
  const [promptCount, setPromptCount] = useState(0); 
  const [limitReached, setLimitReached] = useState(false); 
  const [isNewChat, setIsNewChat] = useState(false);
  const isCreatingChat = useRef(false);

  useEffect(() => {
    //const isAgeConfirmed = localStorage.getItem('ageConfirmed') === 'true';
    //setShowAgeConfirmation(!isAgeConfirmed);
  }, []); 
  
  useEffect(() => {
    const checkAuthStatus = async () => {
      if (isAuthenticated) {
        await fetchChatHistory();
      } else {
        await createUser();
      }
    };
  
    checkAuthStatus();
  }, [isAuthenticated]);


  const createUser = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${baseUrl}/api/create-user/`);
      if (response.data.success) {
        const tempUserId = response.data.user_id;
        setUserId(tempUserId);
        
        // Identify anonymous user in PostHog
        identifyUser(`anonymous_${tempUserId}`, {
          user_type: 'anonymous',
          temp_user_id: tempUserId
        });
        
        console.log("Temp user created");
        await handleCreateNewChat();
      }
    } catch (error) {
      console.error("Error creating user:", error);
      setError("Failed to create a user. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchChatHistory = async () => {
    setLoading(true);
    try {
      const token = await fetchAccessToken();
      const response = await axios.get(`${baseUrl}/api/chat-history/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        const chatHistory = response.data.chat_history;
        console.log("HHIISTORT", chatHistory)
        setChats(chatHistory);
        if (chatHistory.length > 0) {
          setCurrentChatId(chatHistory[0].chat_id);
        } else {
          await handleCreateNewChat();
        }
      }
    } catch (error) {
      console.error("Error fetching chat history:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateChatMessages = (chatId, newMessage, newTitle) => {
    setChats((prevChats) => {
        const updatedChats = prevChats.map((chat) => {
          if (chat.chat_id === chatId) {
            return {
                ...chat,
                title: newTitle || chat.title, 
                messages: [...chat.messages, newMessage],
            };
          }
          return chat;
        });
        return updatedChats;
    });
};

  const handleSendMessage = async (message) => {
    const newMessage = { role: "user", content: message };
    updateChatMessages(currentChatId, newMessage);
    // setLoading(true);
    setError("");

    try {
        let response;

        // Check if the user is authenticated
        if (isAuthenticated) {
            const token = await fetchAccessToken();
            response = await axios.post(
                `${baseUrl}/api/chat/${currentChatId}/`,
                { message },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        } else {
            // Increment the prompt count when the user is not authenticated
            setPromptCount((prevCount) => prevCount + 1);
            // Handle non-authenticated user
            response = await axios.post(
                `${baseUrl}/api/chat/temp/${currentChatId}/`,
                {
                    message,
                    user_id: userId,  // Pass the temporary user ID if available
                }
            );
        }

        // Add bot response to chat
        const botMessage = { role: "assistant", content: response.data.response };
        updateChatMessages(currentChatId, botMessage, response.data.title);
        // setLoading(false);
    } catch (error) {
        const botMessage = { role: "assistant", content: "Hmmm, not sure I understand - perhaps help me by asking in a different way." };
        updateChatMessages(currentChatId, botMessage);
        console.error("Error sending message:", error);
        // setError("Failed to send message. Please try again.");
        // setLoading(false); 
    }
};


useEffect(() => {
  if (!isAuthenticated && promptCount >= 100) {
      setLimitReached(true); 
  }
}, [promptCount, isAuthenticated]);

const handleLimitConfirm = () => {
  loginWithRedirect();
  setLimitReached(false);
};

//   const handleAgeConfirm = () => {
//     setShowAgeConfirmation(false);
//     localStorage.setItem('ageConfirmed', 'true'); 
// };


//   const handleAgeCancel = () => {
//     setShowAgeConfirmation(true); 
//     localStorage.setItem('ageConfirmed', 'false'); 
//     alert("You will be redirected, and the window will now close.");
//     window.location.href = "https://www.google.com";
// };

const handleCreateNewChat = async () => {
  trackEvent('new_chat_created');
  if (isCreatingChat.current) return; // Prevent duplicate calls
  isCreatingChat.current = true;
  setLoading(true);
  
  try {
    let response;
    const token = isAuthenticated ? await fetchAccessToken() : null;
    response = await axios.post(
      isAuthenticated ? `${baseUrl}/api/create-chat/` : `${baseUrl}/api/create-temp-chat/`,
      isAuthenticated ? {} : { user_id: userId },
      {
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      }
    );
    
    if (response.data.success) {
      const newChatId = response.data.chat_id;
      const newChat = { chat_id: newChatId, title: "", messages: [] };
      setChats((prevChats) => [newChat, ...prevChats]);
      setCurrentChatId(newChatId);
      setIsNewChat(true);
    }
  } catch (error) {
    console.error("Error creating new chat:", error);
    setError("Failed to create a new chat. Please try again.");
  } finally {
    setLoading(false);
    isCreatingChat.current = false;
  }
};

useEffect(() => {
  if (isAuthenticated && user) {
    identifyUser(user.email, {
      email: user.email,
      name: user.name,
      auth0_id: user.sub
    });
    trackEvent('user_authenticated');
  }
}, [isAuthenticated, user]);

return (
  <div className="App">
    {/* {showAgeConfirmation ? (
        <AgeConfirmation onConfirm={handleAgeConfirm} onCancel={handleAgeCancel} />
    ) : */}
    {loading ? (
        <Loading />
    ) :
    limitReached ? (
      <LimitReached onConfirm={handleLimitConfirm} />
    ) : (
      <>
        <Header
          onCreateNewChat={handleCreateNewChat}
          chatHistory={chats}
          setCurrentChatId={setCurrentChatId}
          currentChatId ={currentChatId }
        />
        <div className="chat-container">
          <Window
            messages={
              chats.find((chat) => chat.chat_id === currentChatId)?.messages || []
            }
            onSendMessage={handleSendMessage}
            isNewChat={isNewChat}
            resetNewChat={() => setIsNewChat(false)}
          />
        </div>
        {loading && <div className="loading-message"></div>}
        {error && <div className="error-message">{error}</div>}
      </>
    )}
  </div>
);

};

export default AppContent;
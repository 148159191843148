import React from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading/Loading";
import AppContent from "./AppContent";
import DeploymentChecker from './components/DeploymentChecker/DeploymentChecker';
import AdminPanel from './components/Admin/AdminPanel';
import "./App.css";

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <DeploymentChecker />
      <Routes>
        <Route path="/*" element={<AppContent />} />
        <Route path="/admin" element={<AdminPanel />} />
      </Routes>
    </>
  );
};

export default App;
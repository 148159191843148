// Full Terms of Service content
const tosContent = `
<h2>Terms of Service</h2>

<strong>1. Acceptance of Terms</strong><br/>
Welcome to Bragr. By accessing or using Bragr's website, mobile application, and related services (collectively, the "Services") you agree to be bound by these Terms of Service ("TOS"). Please read these terms carefully before using our Services. By using the Services, you are indicating your acceptance of these TOS and your agreement to abide by them. If you do not agree to these terms, you should not access or use Bragr.<br/><br/>
Your use of the Services is also subject to all applicable laws and regulations. These TOS constitute a legally binding agreement between you and Bragr. By using the Services you represent and warrant that you have the right, authority, and capacity to enter into this agreement and to abide by all of its terms and conditions.<br/><br/>

<strong>2. Changes to the Terms</strong><br/>
Bragr reserves the right to modify these TOS at any time and such modifications shall be effective immediately upon posting. We encourage you to review these TOS periodically for any updates or changes. Your continued use of the Services following the posting of changes to these TOS will mean you accept those changes.<br/><br/>

<strong>3. Description of Services</strong><br/>
Bragr provides a generative AI-powered sports companion offering real-time sports updates, interactive features, personalized content, and recommendations for sporting events based on statistical analysis and odds (the "Content").<br/><br/>

<strong>4. Eligibility</strong><br/>
Persons under the age of 21 are prohibited from registering on Bragr. By creating an account, you confirm that you are at least 21 years old or of legal age in your jurisdiction to access our information and services.<br/><br/>

<strong>5. Account Registration and Security</strong><br/>
In order to use certain features of the Services, you may be required to register for an account. When registering, you agree to:
<ul>
  <li>Provide true, accurate, current, and complete information about yourself as prompted by the registration form.</li>
  <li>Maintain and promptly update the registration data to keep it true, accurate, current, and complete.</li>
  <li>Be responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or mobile device.</li>
</ul>
<br/>

<strong>6. Privacy Policy</strong><br/>
Your use of the Services is also governed by our Privacy Policy, which explains how we collect, use, and protect your personal information.<br/><br/>

<strong>7. User Conduct</strong><br/>
You agree to use the Services only for lawful purposes and in accordance with these TOS. You agree not to engage in any activity that may:
<ul>
  <li>Violate any applicable local, state, national, or international law or regulation.</li>
  <li>Involve any unauthorized use of any Content provided through the Services.</li>
  <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity.</li>
</ul>
<br/>

<strong>8. Use of the Services; Restrictions</strong><br/>
You are permitted to access and use the Services and Content solely for your personal, non-commercial use. You agree not to:
<ul>
  <li>Copy, reproduce, republish, upload, post, transmit, or distribute any part of the Services or Content without our prior written consent.</li>
  <li>Attempt to gain unauthorized access to any portion or feature of the Services or any other systems or networks connected to the Services.</li>
  <li>Reverse engineer, decompile, disassemble, or attempt to reduce to a human-perceivable form any of the source code used in providing the Services.</li>
</ul>
<br/>

<strong>9. Betting Disclaimer</strong><br/>
Bragr may provide recommendations for sporting events based on statistical analysis and odds. These recommendations are for informational and entertainment purposes only.<br/><br/>

<strong>10. User-Generated Content</strong><br/>
By submitting any content to the Services ("User Content"), you grant Bragr a worldwide, non-exclusive, royalty-free, perpetual, irrevocable license to use, reproduce, modify, adapt, publish, and create derivative works from it.<br/><br/>

<strong>11. Intellectual Property Rights</strong><br/>
All content and materials on the Services, including but not limited to text, graphics, logos, icons, images, and data compilations, are the property of Bragr.<br/><br/>

<strong>12. Linking to the Services</strong><br/>
You may link to our homepage provided you do so in a way that is fair and legal and does not damage our reputation.<br/><br/>

<strong>13. Third-Party Links and Resources</strong><br/>
The Services may contain links to third-party websites or resources. Bragr has no control over such websites and resources.<br/><br/>

<strong>14. Advertisers and Vendors</strong><br/>
Your correspondence or business dealings with, or participation in promotions of advertisers and vendors found on or through the Services, including payment and delivery of related goods or services, are solely between you and such advertiser/vendor.<br/><br/>

<strong>15. Modifications to Services</strong><br/>
We reserve the right to modify, suspend, or discontinue the Services or any part thereof, temporarily or permanently, at any time with or without notice.<br/><br/>

<strong>16. Termination</strong><br/>
Bragr reserves the right to suspend or terminate your access to the Services at any time.<br/><br/>

<strong>17. Disclaimer of Warranties</strong><br/>
YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.<br/><br/>

<strong>18. Limitation of Liability</strong><br/>
BRAGR SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES.<br/><br/>

<strong>19. Indemnification</strong><br/>
You agree to indemnify, defend, and hold harmless Bragr, its officers, directors, employees, and agents from and against any claims, liabilities, damages, or losses.<br/><br/>

<strong>20. Exclusions and Limitations</strong><br/>
Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages.<br/><br/>

<strong>21. Governing Law</strong><br/>
These TOS and your use of the Services are governed by the laws of the State of Colorado.<br/><br/>

<strong>22. General Provisions</strong><br/>
These TOS constitute the entire agreement between you and Bragr regarding the use of the Services.<br/><br/>

<strong>23. Contact Information</strong><br/>
If you have any questions or concerns about these TOS, please contact us at info@bragr.com.
`;

export default tosContent;

// LimitReached.js
import React from 'react';
import './LimitReached.css';

const LimitReached = ({ onConfirm }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Limit Reached</h2>
                <p>Bragr AI us designed to remember your stuff – so it works better when you're logged in.
                    We limit non-registered users to five chats.  Register by clicking the button below or sign-in here</p>
                <button className="go-to-login-button" onClick={onConfirm}>
                    Go to Login
                </button>
            </div>
        </div>
    );
};

export default LimitReached;
import React, { useState, useEffect } from 'react';
import './ChatPrompts.css';
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const ChatInput = ({ onSendMessage, isNewChat, resetNewChat, hasExistingMessages, isLoading }) => {
    const [input, setInput] = useState('');
    const [showPrompts, setShowPrompts] = useState(!hasExistingMessages);
    const [prompts, setPrompts] = useState([]);  // State to hold the fetched prompts

    // Fetch prompts from an API or another source
    const fetchPrompts = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/admin/prompts`); // Replace with your actual API endpoint
            const data = await response.data;
            setPrompts(data.prompts); // Assuming the API returns a JSON object with a 'prompts' field
        } catch (error) {
            console.error('Error fetching prompts:', error);
        }
    };

    useEffect(() => {
        // Fetch prompts on component mount
        fetchPrompts();
    }, []); // Empty dependency array to run once when the component is mounted

    useEffect(() => {
        if (isNewChat) {
            setShowPrompts(true); // Show prompts for a new chat
            resetNewChat(); // Reset the new chat state after handling
        }
    }, [isNewChat, resetNewChat]);

    useEffect(() => {
        setShowPrompts(!hasExistingMessages);
    }, [hasExistingMessages]);

    const handlePromptClick = (prompt) => {
        onSendMessage(prompt); // Send the prompt as a message
        setShowPrompts(false); // Hide prompts after sending
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (input.trim()) {
            onSendMessage(input);
            setInput('');  // Clear input field after sending
            setShowPrompts(false);  // Hide prompts after sending
        }
    };

    return (
        <div className="chat-input-container">
            {showPrompts && (
                <div className="chat-prompts">
                    {prompts.length > 0 ? (
                        prompts.map((prompt, index) => (
                            <button 
                                key={index} 
                                className="chat-prompt-button" 
                                onClick={() => handlePromptClick(prompt)}>
                                {prompt}
                            </button>
                        ))
                    ) : (
                        <p>Loading prompts...</p> // Display loading message while prompts are being fetched
                    )}
                </div>
            )}

            <form className="chat-input-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Chat Now"
                    className="chat-input-field"
                />
                <button type="submit" className="chat-send-button" disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Chat'}
                </button>
            </form>
        </div>
    );
};

export default ChatInput;

import React, { useState } from 'react';
import './AdminPanel.css';
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

const AdminPanel = () => {
    const [accessCode, setAccessCode] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [prompts, setPrompts] = useState([]);

    const handleLogin = async (e) => {
        e.preventDefault();
        if (accessCode === 'admin@bragr#1508') {
            setIsAuthenticated(true);
            try {
                const response = await axios.get(`${baseUrl}/api/admin/prompts`);
                const data = await response.data;
                if (data.success && data.prompts && data.prompts.length === 6) {
                    setPrompts(data.prompts); // Set fetched prompts as initial state
                }
            } catch (error) {
                console.error("Failed to fetch prompts:", error);
                alert('Failed to load prompts.');
            }
        } else {
            alert('Invalid access code');
        }
    };

    // College Football: Top Same Game Parlays?

    const handlePromptChange = (index, value) => {
        const newPrompts = [...prompts];
        newPrompts[index] = value;
        setPrompts(newPrompts);
    };

    const handleSavePrompts = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/admin/prompts`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompts })
            });
            if (response.status === 400)
                alert('Prompts cannot be empty')
            else
                alert('Prompts updated successfully!');
        } catch (error) {
            alert('Failed to update prompts');
        }
    };

    if (!isAuthenticated) {
        return (
            <div className="admin-login">
                <h2>Admin Access</h2>
                <form onSubmit={handleLogin}>
                    <input
                        type="password"
                        value={accessCode}
                        onChange={(e) => setAccessCode(e.target.value)}
                        placeholder="Enter access code"
                    />
                    <button type="submit">Login</button>
                </form>
            </div>
        );
    }

    return (
        <div className="admin-panel">
    <h2>Manage Chat Prompts</h2>
    <div className="prompts-grid">
        {prompts.map((prompt, index) => (
            <div key={index} className="prompt-box">
                <textarea
                    value={prompt}
                    onChange={(e) => handlePromptChange(index, e.target.value.slice(0, 46))} // Restrict input length to 40
                    placeholder={`Prompt ${index + 1}`}
                    maxLength={46} // Optional: Add HTML maxlength attribute for client-side enforcement
                />
                <p className="char-count">{`${prompt.length}/46`}</p> {/* Display character count */}
            </div>
        ))}
    </div>
    <button className="save-button" onClick={handleSavePrompts}>
        Save Changes
    </button>
</div>
    );
};

export default AdminPanel;

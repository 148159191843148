import React, { createContext, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const fetchAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  return (
    <TokenContext.Provider value={{ fetchAccessToken }}>
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = () => useContext(TokenContext);
import React from 'react';
import ReactMarkdown from 'react-markdown';
import './ChatMessage.css'

const ChatMessage = ({ message, role, }) => {
    return (
        <div className={`chat-message ${role}`}>
            <div className={`chat-message-content ${role}`}>
                <div className="message-container">
                    {role === 'assistant' && (
                        <div className="ball-container">
                            <img className="ball" src="BragrBall.png" alt="Assistant Icon" />
                        </div>
                    )}
                    <ReactMarkdown className="markdown-content">{message}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
};



export default ChatMessage;

import React, { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';  // Auth0 hook
import './Account.css';
import { useToken } from "../../context/TokenContext.js";
const baseUrl = process.env.REACT_APP_BASE_URL;
const AccountSettings = ({ onClose }) => {
    const { isAuthenticated, user} = useAuth0();  // Use Auth0 for auth state
    const [email, setEmail] = useState(user?.email || '');  // Get the email from the authenticated user
    const { fetchAccessToken } = useToken();
    const handlePasswordReset = async () => {
        try {
            let response;
            // If the user is authenticated, fetch the access token
            if (isAuthenticated) {
                const token = await fetchAccessToken();
                console.log("This is Token:::",token)
                // Send the password reset request to your backend
                response = await axios.post(
                    `${baseUrl}/api/reset-password/`,
                    { email: user.email },  // Send the authenticated user's email
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            } else {
                // If the user is not authenticated, use the email entered in the input
                response = await axios.post(
                    `${baseUrl}/api/reset-temp-password/`,  // A different endpoint for unauthenticated users
                    { email },  // Send the email entered by the user
                    {
                        headers: {},
                    }
                );
            }
            if (response.data.success) {
                alert('Password reset email sent successfully.');
            } else {
                alert(`Error: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Error sending password reset request:', error);
            alert('There was an error processing your request.');
        }
    };
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>X</button>
                <h2>Account Settings</h2>
                <div className="form-group">
                    <label>Email Address</label>
                    <input
                        type="email"
                        value={isAuthenticated ? user.email : email}  // Use authenticated email if available
                        onChange={(e) => setEmail(e.target.value)}  // Enable changing email if needed
                        disabled={isAuthenticated}  // Disable input if user is authenticated
                    />
                </div>
                <button className="chat-send-button" onClick={handlePasswordReset}>
                    Reset Password
                </button>
                <p>A password reset email will be sent to {isAuthenticated ? user.email : 'your email address'}.</p>
            </div>
        </div>
    );
};
export default AccountSettings;
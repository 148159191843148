// src/utils/analytics.js
export const identifyUser = (userId, properties = {}) => {
    if (window.posthog) {
      window.posthog.identify(userId, properties);
    }
  };
  
  export const trackEvent = (eventName, properties = {}) => {
    if (window.posthog) {
      window.posthog.capture(eventName, properties);
    }
  };
  
  export const setUserProperties = (properties) => {
    if (window.posthog) {
      window.posthog.people.set(properties);
    }
  };
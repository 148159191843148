import React, { useEffect, useRef, useState } from 'react';
import ChatInput from '../../components/Chat/ChatInput.js';
import ChatMessage from '../../components/Message/ChatMessage.js';
import './Window.css'

const Window = ({ messages, onSendMessage, isNewChat, resetNewChat }) => {
    const messagesEndRef = useRef(null); 
    const [isLoading, setIsLoading] = useState(false); 
    const [loadingText, setLoadingText] = useState('Thinking...'); 
    const [processedMessages, setProcessedMessages] = useState(messages); 

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [processedMessages]);

    const handleSendMessage = async (message) => {
        if (isLoading) return;

        setIsLoading(true);  

        const userMessage = { role: 'user', content: message };
        setProcessedMessages(prevMessages => [...prevMessages, userMessage]);

        const loadingStages = [
            '90% of this game is half mental...',
            'A nickel ain\'t worth a dime anymore...',
            'The more I practice, the luckier I get...',
            'You miss 100% of the shots you don\'t take...',
            'Sometimes in football you have to score goals...',
            'It ain\'t over \'til it\'s over...'
        ];
        let stageIndex = 0;

        const loadingInterval = setInterval(() => {
            if (stageIndex < loadingStages.length) {
                setLoadingText(loadingStages[stageIndex]);
                stageIndex++;
            }
        }, 2800); 


        await onSendMessage(message);

        clearInterval(loadingInterval);
        setLoadingText('Here is your answer');
        setIsLoading(false); 
    };

    useEffect(() => {
        setProcessedMessages(messages);
    }, [messages]);

    return (
        <div className="chat-window">
            <div className="messages">
                {processedMessages.map((msg, index) => (
                    <div key={index}>
                        <ChatMessage message={msg.content} role={msg.role} />
            </div>

                ))}

                {isLoading && (
                    <div className="loading-container">
                        <img className="rotating-ball" src="BragrBall.png" alt="Loading..." />
                        <span className="loading-text">{loadingText}</span>
                    </div>
                )}

                <div ref={messagesEndRef} />
            </div>

            <ChatInput 
                onSendMessage={handleSendMessage} 
                isNewChat={isNewChat} 
                resetNewChat={resetNewChat} 
                isLoading={isLoading}
                hasExistingMessages={processedMessages.length > 0}
            />

        </div>
    );
};

export default Window;

import React from 'react';
import './Loading.css'

const Loading = () => {
    return (
        // <div className="loading">
        //     <h1>Loading Bragr</h1>
        // </div>
        <div className="load-container">
            <h1>Loading Bragr</h1>
            <img className="rotate-ball" src="BragrBall.png" alt="Loading..." />
    </div>
    );
};

export default Loading;
import { useEffect } from 'react';

// Utility to clear cache, localStorage, and cookies
const clearClientStorage = () => {
  // Clear localStorage
  localStorage.clear();

  // Clear sessionStorage
  sessionStorage.clear();

  // Clear cookies
  document.cookie.split(";").forEach((cookie) => {
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  // Clear cache
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
};

// Component to check and handle new deployment
const DeploymentChecker = () => {
  useEffect(() => {
    const storedVersion = localStorage.getItem('appVersion');
    const currentVersion = process.env.REACT_APP_VERSION;

    // If the versions don't match, clear storage and update the stored version
    if (storedVersion !== currentVersion) {
      clearClientStorage();
      localStorage.setItem('appVersion', currentVersion);
      window.location.reload();  // Reload the page after clearing
    }
  }, []);

  return null; // This component doesn't render anything
};

export default DeploymentChecker;

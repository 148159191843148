import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [showThankYou, setShowThankYou] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulate form submission logic
    console.log('Form submitted', formData);

    // Show the "Thank you" message
    setShowThankYou(true);

    // Close the contact form after 2 seconds
    setTimeout(() => {
      setShowThankYou(false);
      onClose(); // Trigger closing the contact form popup
    }, 2500);
  };

  return (
    <div className="contact-container">
      {showThankYou ? (
        <div className="thank-you-message">
          <h2>Thank You for Your Response!</h2>
          <p>We will get back to you shortly.</p>
        </div>
      ) : (
        <>
          <div className="logo-container">
            <img 
              src="BRAGR_ChatGPT_Blue.png" 
              alt="Bragr AI Logo" 
              className="chat-header-logo"
            />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Mike"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Contact Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <small>This is how we will contact you if a reply is needed</small>
            </div>
            <div className="form-group">
              <label htmlFor="message">Please enter your question or comment below</label>
              <textarea
                id="message"
                name="message"
                placeholder="Type here"
                value={formData.message}
                onChange={handleChange}
                maxLength={600}
                required
              />
              <small>Text limit: 600 characters</small>
            </div>
            <button type="submit" className="submit-btn">
              SUBMIT
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default ContactForm;

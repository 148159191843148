import React, { useState, useRef, useEffect } from 'react';
import ContactForm from '../../components/ContactForm/ContactForm.js';
import { useAuth0 } from "@auth0/auth0-react";
import tosContent from '../../components/TermsOfService/TermsOfService.js';
import privacyPolicyContent from '../../components/PrivacyPolicy/PrivacyPolicy.js';
import './Header.css';
import AccountSettings from '../../components/AccountSettings/Account.js';

const Header = ({ onCreateNewChat, chatHistory, setCurrentChatId, currentChatId }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(""); // Store TOS or Privacy Policy
  const [showContactForm, setShowContactForm] = useState(false); // State for Contact Form
  const { loginWithRedirect, logout, user } = useAuth0();
  const [showAccountSettings, setShowAccountSettings] = useState(false);
  const modalRef = useRef(null); // Reference to modal content
  const dropdownRef = useRef(null); // Reference to the dropdown menu
  const toggleButtonRef = useRef(null); // Reference to the toggle button

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const toggleDropdown = () => {
    setDropdownVisible(prev => !prev);
  };

  const handleShowModalTOS = () => {
    setModalContent(tosContent);
    setShowModal(true);
    setDropdownVisible(false);
  };

  const handleShowModalPrivacy = () => {
    setModalContent(privacyPolicyContent);
    setShowModal(true);
    setDropdownVisible(false);
  };

  const handleShowContactForm = () => {
    setShowContactForm(true);
    setDropdownVisible(false);
  };

  const handleCloseContactForm = () => {
    setShowContactForm(false); // Close the contact form modal
  };
  const handleShowAccountSettings = () => {
    setShowAccountSettings(true);  // <-- Trigger Account Settings modal
    setDropdownVisible(false); // Hide dropdown after click
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseAccountSettings = () => {
    setShowAccountSettings(false);  // <-- Close Account Settings modal
  };
  const handleSelectChat = (chatId) => {
    setCurrentChatId(chatId);
    setDropdownVisible(false); // Close dropdown when selecting a chat
  };

  const handleGoToLogin = () => {
    loginWithRedirect();
  };

  // Close dropdown if click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        toggleButtonRef.current && !toggleButtonRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
      <div className="chat-header">
        <img
            src="MenuIcon.png"
            alt="Toggle Menu"
            className="toggle-sidebar-button"
            onClick={toggleDropdown}
            ref={toggleButtonRef}
        />
        <img
            src="BragrAI.png"
            alt="Bragr AI Logo"
            className="chat-header-logo"
        />
        <button className="new-chat-button" onClick={onCreateNewChat}>
            <img src="writenew.png" alt="New Chat" className="new-chat-icon" />
        </button>

        {dropdownVisible && (
          <div className="dropdown-menu" ref={dropdownRef}>
            <ul>
              <li className="chat-history-title">Chat History</li>
              <div className="chat-history-list">
                {chatHistory.map((chat, index) => (
                  <li key={chat.chat_id} onClick={() => handleSelectChat(chat.chat_id)} className={chat.chat_id === currentChatId ? 'selected-chat' : ''} >
                    {chat.title.trim() ? chat.title : 'New Chat'}
                  </li>
                ))}
              </div>
              <div className="section-divider"></div>
                <li onClick={user ? handleShowAccountSettings : null}
                    className={user ? "" : "disabled"}>
                  Account Settings
                </li>
                <div className="section-divider"></div>
                <li onClick={handleShowModalTOS}>Terms of Use</li>
                <li onClick={handleShowModalPrivacy}>Privacy Policy</li>
                <li onClick={handleShowContactForm}>Contact Us</li>
                <div className="section-divider"></div>
                <li
                  onClick={user ? handleLogout : handleGoToLogin}
                  className={user ? "" : ""}>
                  {user ? "Log Out" : "Sign In"}
                </li>

                {!user && (
                  <li onClick={handleGoToLogin} className="">
                    Register Now
                  </li>
                )}
              </ul>
            </div>
        )}
      {showModal && (
          <div className="terms-modal-overlay">
              <div className="terms-modal-content" ref={modalRef}>
                  <button className="close-button" onClick={handleCloseModal}>X</button>
                  <div className="modal-text" dangerouslySetInnerHTML={{ __html: modalContent }} />
              </div>
          </div>
      )}
      {showContactForm && (
          <div className="terms-modal-overlay">
              <div className="terms-modal-content" ref={modalRef}>
                  <button className="close-button" onClick={handleCloseContactForm}>X</button>
                  <ContactForm onClose={handleCloseContactForm} />
              </div>
          </div>
      )}
      {showAccountSettings && (
              <div className="terms-modal-overlay">
                  <div ref={modalRef}>
                      <button className="close-button" onClick={handleCloseAccountSettings}>X</button>
                      <AccountSettings onClose={handleCloseAccountSettings} />
                  </div>
              </div>
          )}

    </div>
  );
};

export default Header;
